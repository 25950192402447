import dayjs, { Dayjs } from 'dayjs'
import { ReactNode } from 'react'
import {
  BooleanToString,
  CellChanged,
  CellData,
  ChangeType,
  HistoryEventType,
  InputTypeOfHistory,
  LineChanged,
  LineData,
  LineTypeDataChangeHistory,
} from './table/model'
import {
  CdnForConvert,
  CommentaireForConvert,
  ConditionnementForHistory,
  GestionForConvert,
  IdentificationForConvert,
  RefFournissersForConvert,
  RefFournissersForHistory,
  ReferenceForHistory,
  ReferenceHistoriesResponse,
} from '../../models/History'
import { ceil, cloneDeep } from 'lodash'
import { Unit } from '../../enum/Unit'
import { GroupType, HistoryType } from './enum/enum'
import { randomString } from '../../utils'

const namesOfPhotoType = ['photo']

const namesOfSwitchType = [
  'dluo',
  'dlv',
  'dlc',
  'gestion',
  'blocage_entree',
  'blocage_sortie',
  'variante_gestion',
  'att_enable',
  'qte_variable',
]
const namesWithUnit = [
  {
    name: 'poids_net',
    unit: Unit.KG,
  },
  {
    name: 'poids_brut',
    unit: Unit.KG,
  },
  {
    name: 'poids_add',
    unit: Unit.KG,
  },
  {
    name: 'longueur',
    unit: Unit.CM,
  },
  {
    name: 'largeur',
    unit: Unit.CM,
  },
  {
    name: 'hauteur',
    unit: Unit.CM,
  },
]

export const MinDayForSearch = '1970-01-01'
export const MaxDayForSearch = dayjs().format('YYYY-MM-DD')

export function genTitle(
  type: string,
  id: string,
  historyType: HistoryType
): ReactNode {
  return (
    <div className="">
      <span>
        <span className="modal-title-heading-1 mr-2">{type}</span>
        {historyType === HistoryType.REF && (
          <span className="text-title-popup">(Référence n° {id})</span>
        )}
        {historyType === HistoryType.SSCC && (
          <span className="text-title-popup">(SSCC n° {id})</span>
        )}
        {historyType === HistoryType.ATTENDU && (
          <span className="text-title-popup">(Attendu n° {id})</span>
        )}
        {historyType === HistoryType.CMD && (
          <span className="text-title-popup">(Commande n° {id})</span>
        )}
      </span>
    </div>
  )
}

export function disabledDateBefore(minDate: Dayjs, currentDate: Dayjs) {
  return currentDate.isBefore(minDate)
}

export function disabledDateAfter(maxDate: Dayjs, currentDate: Dayjs) {
  return currentDate.isAfter(maxDate)
}

export function getDifferenceBetween2Cells(
  oldCell: CellData,
  newCell: CellData
): CellChanged | undefined {
  if (oldCell.name !== newCell.name) return
  if (oldCell.value === newCell.value) return
  const result = {
    name: newCell.name,
    oldValue: oldCell.value,
    newValue: newCell.value,
    changeType: ChangeType.EDIT,
    inputType: InputTypeOfHistory.CELL,
    unit: namesWithUnit.find((item) => item.name === newCell.name)?.unit,
  }

  let inputType = InputTypeOfHistory.CELL
  if (oldCell.name) inputType = getInputType(oldCell.name)
  if (newCell.name) inputType = getInputType(newCell.name)

  result.inputType = inputType

  return result
}

export function getCellsAdded(oldCells: CellData[], newCells: CellData[]) {
  const oldNames = getAllCellNames(oldCells)
  return newCells.filter((cell) => !oldNames.includes(cell.name))
}

export function getCellsDeleted(oldCells: CellData[], newCells: CellData[]) {
  const newNames = getAllCellNames(newCells)
  return oldCells.filter((cell) => !newNames.includes(cell.name))
}

export function getCellsRemain(oldCells: CellData[], newCells: CellData[]) {
  const oldNames = getAllCellNames(oldCells)
  const newNames = getAllCellNames(newCells)
  const intersectionNames = newNames.filter((name) => oldNames.includes(name))
  return newCells.filter((cell) => intersectionNames.includes(cell.name))
}

export function getCellFromCells(name: string, cells: CellData[]) {
  return cells.find((cell) => cell.name === name)
}

export function getAllCellNames(cells: CellData[]) {
  return cells.map((cell) => cell.name)
}

export function getDifferenceBetweenTwoListCell(
  oldList: CellData[],
  newList: CellData[],
  ignoreNames?: string[]
): CellChanged[] {
  let result: CellChanged[] = []

  const cellsAdded = getCellsAdded(oldList, newList)
  const cellsDeleted = getCellsDeleted(oldList, newList)
  const cellsRemain = getCellsRemain(oldList, newList)
  cellsAdded.forEach((cell) => {
    const inputType = getInputType(cell.name)
    let newValue = cell.value
    if (inputType === InputTypeOfHistory.SWITCH)
      newValue = newValue ? BooleanToString.TRUE : BooleanToString.FALSE
    result.push({
      name: cell.name,
      newValue,
      changeType: ChangeType.ADD,
      inputType,
    })
  })
  cellsDeleted.forEach((cell) => {
    const inputType = getInputType(cell.name)
    let oldValue = cell.value
    if (inputType === InputTypeOfHistory.SWITCH)
      oldValue = oldValue ? BooleanToString.TRUE : BooleanToString.FALSE
    result.push({
      name: cell.name,
      oldValue,
      changeType: ChangeType.DELETE,
      inputType: getInputType(cell.name),
    })
  })
  cellsRemain
    .map((cell) => cell.name)
    .filter((name) => !ignoreNames?.includes(name))
    .forEach((name) => {
      const oldCell = getCellFromCells(name, oldList)
      const newCell = getCellFromCells(name, newList)

      if (oldCell && newCell) {
        const cellChanged = getDifferenceBetween2Cells(oldCell, newCell)
        if (cellChanged) result.push(cellChanged)
      }
    })
  result.forEach((item) => (item.group = GroupType.Edition))
  return result
}

export function objectToCells(obj: string): CellData[] {
  const newObject = JSON.parse(obj)
  const cells: CellData[] = []
  for (const key in newObject) {
    cells.push({ name: key, value: newObject[key] }) // No need to convert, as value is already a string
  }
  return cells
}

function getSymbol(value: string | undefined, unit?: Unit) {
  if (value) return `${value}${unit || ''}`
  return 'Non renseigné'
}

function getLabelFromName(name: string): string {
  switch (name) {
    case 'libelle_long':
      return 'Libellé long'
    case 'marque':
      return 'Marque'
    case 'langua_libelle':
      return 'Langue libellé'
    case 'libelle_court':
      return 'Libellé court'
    case 'famille':
      return 'Famille'
    case 'origine':
      return 'Origine'
    case 'sous_famille':
      return 'Sous-Famille'
    case 'photo':
      return 'Photo'

    case 'dluo':
      return 'DLUO'
    case 'dlv':
      return 'DLV'
    case 'dlc':
      return 'DLC'
    case 'dlc_value':
      return 'Nbr jours (DLC)'
    case 'dluo_value':
      return 'Nbr jours (DLUO)'
    case 'dlv_value':
      return 'Nbr jours (DLV)'
    case 'gestion':
      return 'Gestion de lot'
    case 'blocage_entree':
      return 'Blocage entrée'
    case 'blocage_sortie':
      return 'Blocage sortie'
    case 'alerte_stock':
      return 'Alerte stock'
    case 'niveau_alerte':
      return 'Niveau alerte'
    case 'variante_gestion':
      return 'Variante'

    case 'charement':
      return 'Chargement'
    case 'livraison':
      return 'Livraison'
    case 'reception':
      return 'Réception'

    case 'no_codebarre':
      return 'Code barre'
    case 'poids_net':
      return 'Poids Net'
    case 'poids_brut':
      return 'Poids Brut'
    case 'poids_add':
      return 'Poids Add'
    case 'longueur':
      return 'Longueur'
    case 'largeur':
      return 'Largeur'
    case 'hauteur':
      return 'Hauteur'
    case 'couches':
      return 'Couches'
    case 'type_de_codebarre':
      return 'Type CB'
    case 'code_barre':
      return 'Code barre'
    case 'rotation':
      return 'Rotation'
    case 'support':
      return 'Supports'
    case 'qte':
      return 'Qte / contenant'
    case 'qte_variable':
      return 'Qte Variable'
    case 'variante':
      return 'Variante'
    case 'couche':
      return 'Couches'
    case 'nom':
      return 'Nom'
  }
  return ''
}

function getInputType(name: string): InputTypeOfHistory {
  if (namesOfPhotoType.includes(name)) return InputTypeOfHistory.PHOTO
  if (namesOfSwitchType.includes(name)) return InputTypeOfHistory.SWITCH

  return InputTypeOfHistory.CELL
}

function getLineFromFourTable(id: string, data: RefFournissersForConvert[]) {
  return data.find((line) => line.id === id)
}

export function getElementFromObjectType(objectType: string) {
  switch (objectType) {
    case 'reference':
      return 'Réf'
    case 'attendu':
      return 'Attendu'
    case 'sscc':
      return 'SSCC'
    case 'commande':
      return 'Commande'
  }

  return ''
}

export function getElementFromObjectSearchType(objectType: string) {
  switch (objectType) {
    case 'reference':
      return 'Réf'
    case 'attendu':
      return 'Attendu'
    case 'sscc':
      return 'SSCC'
    case 'commande':
      return 'Commande'
  }

  return ''
}
