import { useTranslation } from 'react-i18next'

interface MovementTooltipProps {
  code?: string
  type?: string
}

/**
 * The MovementTooltip will show tooltip when hovering button
 * @author [anhht]
 * @param {code, type} string
 * @version 0.1
 */

const MovementTooltip = (props: MovementTooltipProps) => {
  const { code, type } = props
  const { t } = useTranslation()

  return (
    <div className="m1-tooltip" data-testid="movement-tooltip">
      {code} - Type : {t(`typeCode.${type}`)}
      {type === '74' || type === '75'
        ? ' (en cours)'
        : type === '24' || type === '25'
        ? ' (effectué)'
        : ''}
      ({type})
    </div>
  )
}

export default MovementTooltip
