import { useEffect, useState } from 'react'
import './CSwitch.scss'

export interface CSwitchProps {
  /**
   * indicate switch is disable or not
   */
  disabled?: boolean

  /**
   * default value of CSwitch
   */
  defaultChecked?: boolean

  /**
   * default checked of CSwitch
   */
  checked?: boolean

  /**
   * trigger when state checking change
   */
  onChange?(value: boolean): void

  /**
   * name of CSwitch
   */
  name?: string

  /**
   * to style for CSwitch
   */
  className?: string
  id?: string
  nextId?: string
  previousId?: string
  /**
   * check if switch is inside Form or not
   */
  inForm?: boolean
  value?: boolean
}

/**
 *
 * The @CSwitch is common switch for this app
 * @version 0.1
 * @author [chiendd]
 *
 */
const CSwitch = (props: CSwitchProps) => {
  const { checked = false, defaultChecked = false } = props
  const [currentChecked, setCurrentChecked] = useState<boolean>(
    Boolean(checked) || Boolean(defaultChecked)
  )

  useEffect(() => {
    setCurrentChecked(checked)
  }, [checked])

  const onToggle = () => {
    if (!props.onChange) return
    if (props.disabled) return
    props.onChange(!currentChecked)
    setCurrentChecked(!currentChecked)
  }

  return (
    <div
      // id={`wrapper-${props.id}`}
      id={props.id}
      data-next-id={props.nextId}
      data-previous-id={props.previousId}
      tabIndex={0}
      className={`switch ${props.className} `}
      onClick={onToggle}
    >
      <input
        checked={currentChecked}
        disabled={props?.disabled}
        type="checkbox"
      />
      <span
        className={`slider round ${
          props?.disabled ? 'cursor-not-allowed' : ''
        }`}
      ></span>
    </div>
  )
}

export default CSwitch
