interface QteltipProps {
  volume?: number
  poids?: number
}

/**
 * The QtelTooltip will show tooltip when hovering button
 * @author [anhht]
 * @version 0.1
 */
const QtelTooltip = (props: QteltipProps) => {
  return (
    <div className="m1-tooltip">
      <div>Volume (cm3) : {props.volume ? props.volume : '-'}</div>
      <div>Poids (kg) : {props.poids ? props.poids : '-'}</div>
    </div>
  )
}

export default QtelTooltip
