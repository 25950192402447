interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function CdnLevel3(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <rect x="9" y="18" width="1" height="2" fill={color} />
      <rect x="13" y="18" width="1" height="2" fill={color} />
      <rect x="17" y="17" width="1" height="3" fill={color} />
      <rect x="7" y="17" width="13" height="1" fill={color} />
      <rect x="7" y="20" width="13" height="1" fill={color} />
      <rect x="7" y="11" width="6" height="5" fill={color} />
      <rect x="14" y="11" width="6" height="5" fill={color} />
      <rect x="10" y="5" width="6" height="5" fill={color} />
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
    </svg>
  )
}
