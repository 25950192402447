import { useEffect } from 'react'

/**
 * useScroll make horizontal scroll start form fixed column when scrolling
 * @param data data of table (Attendu, NewStock, Commande...)
 */
export default function useScroll(data: any) {
  useEffect(() => {
    if (data) {
      const selector = document.querySelector(
        '.rc-virtual-list-scrollbar-horizontal .rc-virtual-list-scrollbar-thumb'
      ) as HTMLElement
      if (selector) {
        //2 fixed left column have width 90px each
        selector.style.marginLeft = '180px'

        document.addEventListener('wheel', () => {
          const fullWidth =
            document.querySelector('.rc-virtual-list-scrollbar-horizontal')
              ?.clientWidth || 1000
          selector.style.transform = `translateX(-${
            (selector.getBoundingClientRect().left / fullWidth) * 182
          }px)`
        })

        document.addEventListener('mousemove', () => {
          const fullWidth =
            document.querySelector('.rc-virtual-list-scrollbar-horizontal')
              ?.clientWidth || 1000
          selector.style.transform = `translateX(-${
            (selector.getBoundingClientRect().left / fullWidth) * 182
          }px)`
        })
      }
    }
  }, [data])
}
