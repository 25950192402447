interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function StatutBloqueEntrpot(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      data-testid="blocked-warehouse-status-icon"
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <rect x="9.06348" y="14" width="2" height="7" fill="#F09637" />
      <rect x="12.0635" y="15" width="2" height="6" fill="#F09637" />
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        d="M6.53906 20.9407V12.2377L13.348 5.82812L20.064 12.2377V20.9407H18.7114V13.2374H7.83276V20.9407H6.53906Z"
        fill={color}
      />
      <circle cx="13.3018" cy="10" r="1" fill="white" />
      <path d="M4.06836 13L13.296 3.99854L22.5237 13" stroke={color} />
      <path
        d="M4.59961 22.5397L22.9463 4.5752"
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  )
}
