import { Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import StatutBloqueEntrpot from '../../../assets/icons/StatutBloqueEntrepot'
import StatutCommercialisable from '../../../assets/icons/StatutCommercialisable'
import StatutTooltip from '../Tooltip/StatutTooltip'
import { useTranslation } from 'react-i18next'

export enum StatutType {
  STATUT_COMMERCIALISABLE = 'satut_commercialisable',
  STATUT_BLOQUE_USINE = 'statut_bloqué_usine',
  STATUT_BLOQUE_ENTREPOT = 'statut_bloqué_entrepot',
  STATUT_CQ = 'statut_cq',
  STATUT_BLOQUE = 'statut_bloqué',
}

interface StatutRenderProps {
  previousStatut?: string
  isHover?: boolean
  blocage_entree?: boolean
  blocage_sortie?: boolean
  isMainLine?: boolean
}

const StatutRender = (props: StatutRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const {
    blocage_entree,
    blocage_sortie,
    isHover,
    previousStatut,
    isMainLine,
  } = props
  const { t } = useTranslation()

  const statutIcon = () => {
    if (blocage_entree || blocage_sortie) {
      return StatutType.STATUT_BLOQUE_ENTREPOT
    } else {
      return StatutType.STATUT_COMMERCIALISABLE
    }
  }

  const curStatut = () => {
    if (blocage_entree || blocage_sortie) {
      if (blocage_entree && blocage_sortie) {
        return t('statut.blockEntreSortie')
      } else if (blocage_entree) {
        return t('statut.blockEntre')
      } else if (blocage_sortie) {
        return t('statut.blockSortie')
      }
    } else {
      return t('statut.available')
    }
  }

  const preStatut = () => {
    if (!previousStatut) {
      return curStatut()
    } else {
      const parsePreviousStatut = JSON.parse(previousStatut)
      const parseBlocEntree = parsePreviousStatut.blocage_entree
      const parseBlocSortie = parsePreviousStatut.blocage_sortie
      if (parseBlocEntree && parseBlocSortie) {
        return t('statut.blockEntreSortie')
      } else if (parseBlocEntree) {
        return t('statut.blockEntre')
      } else if (parseBlocSortie) {
        return t('statut.blockSortie')
      } else {
        return t('statut.available')
      }
    }
  }

  switch (statutIcon()) {
    case StatutType.STATUT_COMMERCIALISABLE:
      return (
        <Tooltip
          title={
            <StatutTooltip
              curStatut={curStatut()}
              prevStatut={preStatut()}
              isMainLine={isMainLine}
            />
          }
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          mouseLeaveDelay={1000}
        >
          <StatutCommercialisable
            color={isHover ? '#FFF' : '#01E37A'}
            fill={isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
    case StatutType.STATUT_BLOQUE_ENTREPOT:
      return (
        <Tooltip
          title={
            <StatutTooltip
              curStatut={curStatut()}
              prevStatut={preStatut()}
              isMainLine={isMainLine}
            />
          }
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <StatutBloqueEntrpot
            color={isHover ? '#FFF' : '#F25524'}
            fill={isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )

    default:
      return <>-</>
  }
}

export default StatutRender
