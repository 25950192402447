import { Tooltip } from 'antd'
import { useState } from 'react'
import { StatusAttendu } from '../../../enum/StatusAttendu'
import DatetimeUserTooltip from '../Tooltip/DatetimeUserTooltip'

interface DatetimeUserProps {
  user?: string
  datetime?: string
  status?: StatusAttendu
}

const DatetimeUserRender = (props: DatetimeUserProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  return (
    <Tooltip
      title={
        <DatetimeUserTooltip user={props.user} datetime={props.datetime} />
      }
      color={'white'}
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip && !!props.datetime}
      mouseEnterDelay={0.3}
    >
      <div
        style={{ width: 'max-content' }}
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
      >
        {props.datetime || '-'}
      </div>
    </Tooltip>
  )
}

export default DatetimeUserRender
