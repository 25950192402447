import { useState } from 'react'
import { Tooltip } from 'antd'
import _ from 'lodash'
import statutData from '../../../constants/statutArray.json'

interface StatusHeaderProps {
  data: Item[]
}

interface Item {
  pre?: string
  detail?: string
}

/**
 * The StatutTitleTooltip will show tooltip when hovering button
 * @author [anhht, anhnq]
 * @version 0.1
 */
function StatutTitleTooltip() {
  const [clientX, setClientX] = useState(-200)
  return (
    <div
      onMouseEnter={(e) => {
        setClientX(66 - e.clientX)
      }}
    >
      <Tooltip
        title={<StatutHeaderTooltip />}
        color={'white'}
        placement="bottom"
        overlayClassName="statut-header-tooltip"
        overlayInnerStyle={{
          height: 60,
          borderRadius: 0,
          position: 'absolute',
          top: 0,
          left: clientX,
          display: 'flex',
          alignItems: 'center',
        }}
        mouseEnterDelay={0.3}
      >
        Statut
      </Tooltip>
    </div>
  )
}

const StatutHeaderTooltip = () => {
  return (
    <div className="mtooltip">
      <Row data={statutData} />
    </div>
  )
}

const Row = (props: StatusHeaderProps) => {
  const { data } = props
  return (
    <div className="flex justify-between item align-middle s-item">
      {_.times(5, (index) => (
        <div className="item-child" key={index}>
          <div className="first-span">
            <span>{data[index].pre}</span>
          </div>
          <div className="last-span">
            <span>{data[index].detail}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StatutTitleTooltip
