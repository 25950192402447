import { Dispatch, useEffect } from 'react'
import { Page } from '../enum'
import { getAllAttendu, getTotalAttendu } from '../redux/reducers/attenduSlice'
import {
  getAllCommande,
  getTotalCommande,
} from '../redux/reducers/commandeSlice'
import { getAllStock, getTotalStock } from '../redux/reducers/stockSlice'
import Define from '../constants/define'
import { shouldRefreshData } from '../utils/modal'

export const useRenderDataByInterval = (data: {
  page: Page
  intervalTime: number
  dispatch: Dispatch<any>
  navigate: any
  pageIndex: string | number
  pageSize: string | number
}) => {
  const { page, intervalTime, dispatch, pageIndex, pageSize, navigate } = data
  const clientCodeNom = localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM)
  const warehouseCodeNom = localStorage.getItem(
    Define.CHOOSING_WAREHOUSE_CODENOM
  )
  const companyCodeNom = localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
  const isSearch = clientCodeNom && warehouseCodeNom && companyCodeNom

  const showAllData = (data: { isRealTime: boolean }) => {
    const { isRealTime } = data
    if (shouldRefreshData()) {
      switch (page) {
        case Page.STOCK:
          dispatch(getAllStock({ isRealTime }))
          break
        case Page.ATTENDUS:
          dispatch(getAllAttendu({ isRealTime }))
          break
        case Page.COMMANDES:
          dispatch(getAllCommande({ isRealTime }))
          break
        default:
          break
      }
    }
  }

  const countTotal = () => {
    if (shouldRefreshData()) {
      switch (page) {
        case Page.STOCK:
          dispatch(getTotalStock())
          break
        case Page.ATTENDUS:
          dispatch(getTotalAttendu())
          break
        case Page.COMMANDES:
          dispatch(getTotalCommande())
          break
        default:
          break
      }
    }
  }

  //get all data real time
  useEffect(() => {
    showAllData({ isRealTime: false })
    if (isSearch) {
      const intervalData = setInterval(
        () => showAllData({ isRealTime: true }),
        intervalTime
      )
      return () => {
        clearInterval(intervalData)
      }
    }
  }, [navigate, pageIndex, pageSize, dispatch, isSearch])

  //count total data real time
  useEffect(() => {
    if (isSearch) {
      const intervalData = setInterval(countTotal, intervalTime)
      return () => {
        clearInterval(intervalData)
      }
    }
  }, [navigate, isSearch])
}
