import { Tooltip } from 'antd'
import { useState } from 'react'
import MovementTooltip from '../Tooltip/MovementTooltip'
import '../../../features/stock/attendu/Attendu.scss'
import { useTranslation } from 'react-i18next'

interface MovementTypeProps {
  movement?: string
  type?: string
  isShowTooltip?: boolean
  isChildren?: boolean
}

const MovementRender = (props: MovementTypeProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <>
      <Tooltip
        title={<MovementTooltip code={props.movement} type={props.type} />}
        color="#FFFFFF"
        overlayInnerStyle={{ borderRadius: 0 }}
        open={isShowTooltip}
        mouseEnterDelay={0.3}
      >
        <div
          style={{ width: 'max-content' }}
          onMouseLeave={() => {
            setIsShowTooltip(false)
          }}
          onMouseEnter={() => {
            setIsShowTooltip(true)
          }}
        >
          <div
            className={
              props.isChildren
                ? 'bg-[#0189E333] rounded-xl px-2 font-semibold text-[#0068AC] mouvement-hover'
                : ''
            }
          >
            {t(`mouvement.${props.movement}`)}
          </div>
        </div>
      </Tooltip>
    </>
  )
}

export default MovementRender
