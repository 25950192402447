interface EtatTooltipProps {
  etat?: string
}

/**
 * The EtatTooltip will show tooltip when hovering button
 * @author [anhht]
 * @param {etat} string ;
 * @version 0.1
 */
const EtatTooltip = (props: EtatTooltipProps) => {
  return (
    <div className="m1-tooltip">
      <div>{props.etat}</div>
    </div>
  )
}

export default EtatTooltip
