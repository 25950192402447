interface ConditionementTooltipProps {
  type?: string
  number?: string
}

/**
 * The ConditionementTooltip will show tooltip when hovering button
 * @author [anhht]
 * @param {type, number} string;
 * @version 0.1
 */
const ConditionementTooltip = (props: ConditionementTooltipProps) => {
  //   const { movements, types, style } = props;
  return (
    <div className="m1-tooltip">
      <div>Nom : {props.type || '-'}</div>
      <div>Numéro : -</div>
    </div>
  )
}

export default ConditionementTooltip
