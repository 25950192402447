import { Tooltip } from 'antd'
import { useState } from 'react'
import QtelTooltip from '../Tooltip/QteToolTooltip'

interface QteRenderProps {
  data?: number
  volume?: number
  poids?: number
  notShowTooltip?: boolean
}

const QteRender = (props: QteRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  if (props.notShowTooltip) {
    return <div style={{ width: 'max-content' }}>{props.data || '-'}</div>
  }

  return (
    <>
      <Tooltip
        title={<QtelTooltip volume={props.volume} poids={props.poids} />}
        color="#FFFFFF"
        overlayInnerStyle={{ borderRadius: 0 }}
        open={isShowTooltip && !!props.data}
        mouseEnterDelay={0.3}
      >
        <div
          style={{ width: 'max-content' }}
          onMouseLeave={() => {
            setIsShowTooltip(false)
          }}
          onMouseEnter={() => {
            setIsShowTooltip(true)
          }}
        >
          {props.data || '-'}
        </div>
      </Tooltip>
    </>
  )
}

export default QteRender
