interface DatetimeUserTooltipProps {
  user?: string
  datetime?: string
}

/**
 * The DatetimeUserTooltip will show tooltip when hovering button
 * @author [anhnq]
 * @param {user, datetime} string
 * @version 0.1
 */
const DatetimeUserTooltip = (props: DatetimeUserTooltipProps) => {
  return (
    <div className="m1-tooltip">
      <div>par : {props.user || '-'}</div>
      <div>le: {props.datetime}</div>
    </div>
  )
}

export default DatetimeUserTooltip
