export enum EtaAttendu {
  CREATED = '0',
  UNASSIGNED = '2',
  ASSIGNED = '3',
  IN_PROGRESS = '4',
  SUSPENDU = '5',
  COMPLETE = '1',
  INSTOCK = 'in-stock',
  MAS_NON_ASSIGNE = '6',
  MAS_ASSIGNE = '7',
  MAS_EN_COURS = '8',
  MAS_SUSPENDU = '9',
  MAS_TERMINEE = '10',
}
