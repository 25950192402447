interface StatutTooltipProps {
  curStatut?: string
  prevStatut?: string
  isMainLine?: boolean
}

/**
 * The StatutTooltip will show tooltip when hovering button
 * @author [anhht, anhnq]
 * @version 0.1
 */
const StatutTooltip = (props: StatutTooltipProps) => {
  return (
    <div data-testid="status-tooltip" className="m1-tooltip  p-1">
      <div>
        <div className="font-semibold">{props.curStatut}</div>
      </div>
      {!props.isMainLine && (
        <div className="text-xs">Statut précédent : {props.prevStatut} </div>
      )}
    </div>
  )
}

export default StatutTooltip
