import { Tooltip, notification } from 'antd'
import { useState } from 'react'
import ConditionementTooltip from '../Tooltip/ConditionnamentTooltip'
import Define from '../../../constants/define'
import CdnLevel3 from '../../../assets/icons/CdnLevel3'
import CdnLevel2 from '../../../assets/icons/CdnLevel2'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { StockDataType } from '../../../models'
import { getCurrentFiltersText } from '../../../utils'

interface ConditionnementRenderProps {
  type: string
  countCdn?: number
  isMainLine?: boolean
  niveau?: number
  isHover?: boolean
  stock?: StockDataType
  canOpenPopup?: boolean
}

const ConditionnementRender = (props: ConditionnementRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const navigate = useNavigate()

  const renderCdnIcon = (niveau: number) => {
    switch (niveau) {
      case Define.MAX_CDN_LEVEL:
        return (
          <CdnLevel3
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        )
      case Number(Define.MAX_CDN_LEVEL - 1):
        return (
          <CdnLevel2
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        )
      default:
        return <>{props.type || '-'}</>
    }
  }

  const openCdnPopup = () => {
    if (props.isMainLine) return
    if (props.canOpenPopup) {
      const condition = Object.fromEntries(
        new URLSearchParams(window.location.search)
      )
      const pageIndex = Number(condition['page-index']) || 1
      const pageSize = Number(condition['page-size']) || 25
      navigate(
        `conditionnement?sscc_stock=${props.stock?.sscc}&lot_stock=${
          props.stock?.lot
        }&ref_id_stock=${props.stock?.reference_id}&stock_id=${
          props.stock?.id
        }&page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    }
  }

  return (
    <Tooltip
      title={<ConditionementTooltip type={props.type} />}
      color="#FFFFFF"
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip && !!props.type && !props.countCdn}
      mouseEnterDelay={0.3}
    >
      <span
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
        onClick={openCdnPopup}
      >
        {props.countCdn && props.countCdn > 1 ? (
          <div
            className="rounded-full  bg-[#0189E3] text-center mouvement-hover"
            style={{ height: 23, width: 23 }}
          >
            <div
              className="flex justify-center items-center"
              style={{ height: 23 }}
            >
              <div className="text-white mouvement-hover-text">
                {props.countCdn}
              </div>
            </div>
          </div>
        ) : (
          <>
            {props.isMainLine ? (
              <>{renderCdnIcon(props.niveau || 0)}</>
            ) : (
              <div className={props.canOpenPopup ? 'cursor-pointer' : ''}>
                {props.type || '-'}
              </div>
            )}
          </>
        )}
      </span>
    </Tooltip>
  )
}

export default ConditionnementRender
