interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function StatutCommercialisable(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      data-testid="commercial-status-icon"
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        d="M8.111 12.92V11.576H9.663C9.983 10.36 10.591 9.38933 11.487 8.664C12.3937 7.928 13.4923 7.56 14.783 7.56C16.383 7.56 17.7537 8.20533 18.895 9.496L17.471 11.352C16.6923 10.4027 15.775 9.928 14.719 9.928C13.5243 9.928 12.6977 10.4773 12.239 11.576H15.967V12.92H11.951C11.9403 13.0267 11.935 13.1867 11.935 13.4C11.935 13.6133 11.9403 13.768 11.951 13.864H15.967V15.208H12.319C12.575 15.7093 12.9217 16.0933 13.359 16.36C13.807 16.6267 14.2977 16.76 14.831 16.76C15.887 16.76 16.751 16.3387 17.423 15.496L18.895 17.128C18.319 17.8 17.6523 18.3013 16.895 18.632C16.1483 18.9627 15.4017 19.128 14.655 19.128C13.4497 19.128 12.399 18.776 11.503 18.072C10.607 17.3573 9.99367 16.4027 9.663 15.208H8.111V13.864H9.455C9.44433 13.768 9.439 13.6133 9.439 13.4C9.439 13.1867 9.44433 13.0267 9.455 12.92H8.111Z"
        fill={color}
      />
    </svg>
  )
}
