import { Tooltip } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import EtatSuspendu from '../../../assets/icons/EtatSuspendu'
import { EtaAttendu } from '../../../enum/EtaAttendu'
import { EtaCommande } from '../../../enum/EtaCommande'
import { ModalName } from '../../../features/stock/modal/customModalSlice'
import EtatTooltip from '../Tooltip/EtatTooltip'
import './index.scss'

interface EtatRenderProps {
  type: EtaAttendu | EtaCommande
  isMainLine?: boolean
  isHover?: boolean
  screen: ModalName
}

export function mapEtatStatutToString(type?: EtaAttendu) {
  switch (type) {
    case EtaAttendu.COMPLETE:
      return 'Validé'
    case EtaAttendu.UNASSIGNED:
      return 'Envoyé en mission'
    case EtaAttendu.ASSIGNED:
      return 'Affecté'
    case EtaAttendu.IN_PROGRESS:
      return 'En cours'
    case EtaAttendu.SUSPENDU:
      return 'Suspendu'
    case EtaAttendu.CREATED:
      return 'Créé'
    default:
      return 'Créé'
  }
}

interface PercentageInfoInterface {
  borderColor: string
  number: number
  textColor: string
  tooltip: string
}

const EtatRender = (props: EtatRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  const renderPercentage = () => {
    if (props.screen === ModalName.ATTENDU) {
      switch (props.type) {
        case EtaAttendu.MAS_TERMINEE:
        case EtaAttendu.COMPLETE:
          return {
            borderColor: 'border-[#01E37A]',
            textColor: 'text-[#01E37A]',
            number: 100,
            tooltip: t('attenduEtat1'),
          }
        case EtaAttendu.MAS_NON_ASSIGNE:
        case EtaAttendu.UNASSIGNED:
          return {
            borderColor: 'border-[#F09637]',
            textColor: 'text-[#F09637]',
            number: 40,
            tooltip: t('attenduEtat2'),
          }
        case EtaAttendu.MAS_ASSIGNE:
        case EtaAttendu.ASSIGNED:
          return {
            borderColor: 'border-[#F6ED0B]',
            textColor: 'text-[#F6ED0B]',
            number: 60,
            tooltip: t('attenduEtat3'),
          }
        case EtaAttendu.MAS_EN_COURS:
        case EtaAttendu.IN_PROGRESS:
          return {
            borderColor: 'border-[#87E510]',
            textColor: 'text-[#87E510]',
            number: 80,
            tooltip: t('attenduEtat4'),
          }

        case EtaAttendu.CREATED:
          return {
            borderColor: 'border-[#F25524]',
            textColor: 'text-[#F25524]',
            number: 20,
            tooltip: t('attenduEtat0'),
          }

        case EtaAttendu.SUSPENDU:
        case EtaCommande.PendingComande:
        case EtaAttendu.MAS_SUSPENDU:
        default:
          break
      }
    } else {
      switch (props.type) {
        case EtaCommande.CreatedCommande:
          return {
            borderColor: 'border-[#D03200]',
            textColor: 'text-[#D03200]',
            number: 5,
            tooltip: t('commandeEtat0'),
          }

        case EtaCommande.ValidCommande:
          return {
            borderColor: 'border-[#F25524]',
            textColor: 'text-[#F25524]',
            number: 10,
            tooltip: t('commandeEtat1'),
          }

        case EtaCommande.SendOnMissionCommande:
          return {
            borderColor: 'border-[#F25524]',
            textColor: 'text-[#F25524]',
            number: 20,
            tooltip: t('commandeEtat2'),
          }

        case EtaCommande.AssignedCommande:
          return {
            borderColor: 'border-[#F6A356]',
            textColor: 'text-[#F6A356]',
            number: 30,
            tooltip: t('commandeEtat3'),
          }

        case EtaCommande.InProgressCommande:
          return {
            borderColor: 'border-[#EAAE15]',
            textColor: 'text-[#EAAE15]',
            number: 40,
            tooltip: t('commandeEtat4'),
          }

        case EtaCommande.PickupCommande:
          return {
            borderColor: 'border-[#F7C102]',
            textColor: 'text-[#F7C102]',
            number: 50,
            tooltip: t('commandeEtat6'),
          }

        case EtaCommande.MissionUnassigned:
          return {
            borderColor: 'border-[#F6ED0B]',
            textColor: 'text-[#F6ED0B]',
            number: 60,
            tooltip: t('commandeEtat7'),
          }

        case EtaCommande.MissionAssigned:
          return {
            borderColor: 'border-[#DEE301]',
            textColor: 'text-[#DEE301]',
            number: 70,
            tooltip: t('commandeEtat8'),
          }

        case EtaCommande.MissionInprogress:
          return {
            borderColor: 'border-[#87E510]',
            textColor: 'text-[#87E510]',
            number: 80,
            tooltip: t('commandeEtat9'),
          }

        case EtaCommande.MissionDone:
          return {
            borderColor: 'border-[#01E37A]',
            textColor: 'text-[#01E37A]',
            number: 100,
            tooltip: t('commandeEtat11'),
          }

        default:
          break
      }
    }
  }

  return (
    <>
      {props.type === EtaAttendu.SUSPENDU ||
      props.type === EtaCommande.PendingComande ||
      props.type === EtaCommande.MissionPending ? (
        <Tooltip
          title={<EtatTooltip etat="Suspendu" />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <EtatSuspendu
            color={props.isHover ? '#FFF' : '#B7B7B7'}
            opacity={props.isMainLine ? 1 : 0.5}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={<EtatTooltip etat={renderPercentage()?.tooltip} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
        >
          <div
            className={`${
              props.isHover ? 'border-white' : renderPercentage()?.borderColor
            } ${
              props.isMainLine ? 'opacity-100' : 'opacity-50'
            } rounded-full border-2 border-solid text-center`}
            style={{ height: 23, width: 23 }}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          >
            <div
              className="flex justify-center items-center"
              style={{ height: 23 }}
            >
              <div className={`${renderPercentage()?.textColor} etat-text-big`}>
                {renderPercentage()?.number}
                <span className="etat-text-small">%</span>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default EtatRender
