import _ from 'lodash'
import mouvementArray from '../../../constants/mouvementArray.json'
import typesArray from '../../../constants/typesArray.json'

interface MovementHeaderTooltipProps {
  movements?: Item[]
  types?: Type[]
}

interface Type {
  data: Item[]
}

interface Item {
  pre?: string
  detail?: string
}

interface Mouvements {
  data: Item[]
}

/**
 * The MovementHeaderTooltip will show tooltip when hovering button
 * @author [anhht, anhnq]
 * @version 0.1
 */
const MovementHeaderTooltip = (props: MovementHeaderTooltipProps) => {
  return (
    <div className="mtooltip" style={{ width: '100%' }}>
      <DisplayTitle title="Mouvements" />
      <>
        {mouvementArray.map((el, index) => {
          return <Row data={mouvementArray[index]} key={index} />
        })}
      </>
      <DisplayTitle title="Types" />
      <>
        {typesArray.map((el, index) => {
          return <Row data={typesArray[index]} key={index} />
        })}
      </>
    </div>
  )
}

interface DisplayTitleProps {
  title?: string
}

const DisplayTitle = (props: DisplayTitleProps) => {
  return (
    <div className="header-tooltip sm:px-9">
      <p> {props.title}</p>
    </div>
  )
}

const Row = (props: Mouvements) => {
  const { data } = props

  return (
    <div className="flex justify-between item align-middle">
      {data.map((el, index) => (
        <div className="item-child" key={index}>
          <div className="first-span">
            <span>{data[index].pre}</span>
          </div>
          <div className="last-span">
            <span>{data[index].detail}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MovementHeaderTooltip
