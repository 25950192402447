export enum HistoryType {
  REF = 'REF',
  SSCC = 'SSCC',
  ATTENDU = 'ATTENDU',
  CMD = 'COMMANDE',
}

export enum SsccEventType {
  TYPE_1 = '1',
  TYPE_2 = '2',
  TYPE_3 = '3',
  TYPE_4 = '4',
  TYPE_5 = '5',
  TYPE_6 = '6',
  TYPE_7 = '7',
  TYPE_8 = '8',
  TYPE_9 = '9',
  TYPE_10 = '10',
  TYPE_11 = '11',
  TYPE_12 = '12',
  TYPE_13 = '13',
  TYPE_14 = '14',
  TYPE_15 = '15',
  TYPE_16 = '16',
  TYPE_17 = '17',
  TYPE_18 = '18',
  TYPE_19 = '19',
  TYPE_20 = '20',
  TYPE_21 = '21',
  TYPE_22 = '22',
  TYPE_23 = '23',
  TYPE_24 = '24',
  TYPE_25_1 = '25-1',
  TYPE_25_2 = '25-2',
  TYPE_26 = '26',

  TYPE_NULL = '',
}

export enum GroupType {
  Creation = 'Création',
  Suppression = 'Suppression',
  Entree = 'Entrée',
  Sortie = 'Sortie',
  Edition = 'Edition',
  Special_case = 'Special case',
  NULL = '',
}

export enum AttenduEventType {
  A1 = 'A-1',
  A2 = 'A-2',
  A3 = 'A-3',
  A4 = 'A-4',
  A5 = 'A-5',
  A6 = 'A-6',
  A3456 = 'A-3456',
  A7 = 'A-7',
  A8 = 'A-8',
  A9_1 = 'A-9-1',
  A9_2 = 'A-9-2',
  A10 = 'A-10',
  TYPE_NULL = '',
}

export enum CommandeEventType {
  C1 = 'C-1',
  C2 = 'C-2',
  C3 = 'C-3',
  C4 = 'C-4',
  C5 = 'C-5',
  C6 = 'C-6',
  C7 = 'C-7',
  C8 = 'C-8',
  C9 = 'C-9',
  C10 = 'C-10',
  C11 = 'C-11',
  C12 = 'C-12',
  C13 = 'C-13',
  C14 = 'C-14',
  C141 = 'C-14-1',
  C142 = 'C-14-2',
  C15 = 'C-15',
  C16 = 'C-16',
  C17 = 'C-17',
  C18 = 'C-18',
  C34567 = 'C-34567',
  C910111213 = 'C-910111213',
  TYPE_NULL = '',
}

export enum ReferenceEventType {
  R1 = 'R-1',
  R2 = 'R-2',
  R3 = 'R-3',
  R4 = 'R-4',
  R5 = 'R-5',
  R6 = 'R-6',
  R7 = 'R-7',
  R8 = 'R-8',
  R34568 = 'R-34568',
  TYPE_NULL = '',
}
