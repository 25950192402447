interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function CdnLevel2(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        d="M6.35254 10.666V18.7679L13.0432 21.984V14.0676L6.35254 10.666Z"
        fill={color}
      />
      <path
        d="M20.303 10.666V18.7679L13.6123 21.984V14.0676L20.303 10.666Z"
        fill={color}
      />
      <path
        d="M13.5034 7.88611L6.45156 10.6664L4.6123 8.71959L11.8491 6.00586L13.5034 7.88611Z"
        fill={color}
      />
      <path
        d="M13.5039 7.86572L20.3091 10.6719L22.0435 8.79688L15.2935 6L13.5039 7.86572Z"
        fill={color}
      />
      <path
        d="M3.8526 12.7508L11.7765 16.9104L13.501 13.6851L6.51324 10.2117L3.8526 12.7508Z"
        fill="#F2F2F2"
      />
      <path
        d="M4.80937 12.0561L11.533 15.6107L12.7312 13.295L6.45835 10.1285L4.80937 12.0561Z"
        fill={color}
      />
      <path
        d="M22.449 12.7573L14.5252 16.9168L12.8007 13.6916L19.9375 10.1984L22.449 12.7573Z"
        fill="#F2F2F2"
      />
      <path
        d="M21.701 12.0558L14.9774 15.6103L13.7792 13.2946L20.052 10.1282L21.701 12.0558Z"
        fill={color}
      />
    </svg>
  )
}
