import { DownOutlined, UpOutlined } from '@ant-design/icons'
import React from 'react'
import { SortOrder } from 'antd/es/table/interface'

export function getDefaultSorting(
  field: string,
  directionWhenEmpty?: SortOrder
) {
  const searchParams = new URLSearchParams(window.location.search)
  const sortField = searchParams.get('sort')
  if (sortField === field) {
    return searchParams.get('direction') as SortOrder
  } else {
    if (!sortField && directionWhenEmpty) {
      return directionWhenEmpty
    } else {
      return undefined
    }
  }
}

export default function SortColumn({ sortOrder }: { sortOrder?: SortOrder }) {
  return sortOrder === 'ascend' ? (
    <DownOutlined
      style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}
    />
  ) : sortOrder === 'descend' ? (
    <UpOutlined
      style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}
    />
  ) : (
    <DownOutlined
      style={{ fontSize: '16px', color: '#C5C5C5', fontWeight: 'bold' }}
    />
  )
}
