import {
  AttenduHistory,
  ReferenceForHistory,
  ReferenceHistoriesResponse,
  SsccHistory,
} from '../models/History'
import { HistoryRequest } from '../models/request/HistoryRequest'
import baseAxios from './clientAPI'

const historyApi = {
  getRefHistories(payload: {
    id: string
    limit: number
    offset: number
    from?: number
    to?: number
  }): Promise<ReferenceHistoriesResponse> {
    let url = `/warehouse/reference/${payload.id}/history?limit=${payload.limit}&offset=${payload.offset}`
    let param
    if (payload.from && payload.to)
      param = { from: String(payload.from), to: String(payload.to) }
    else if (payload.from) param = { from: String(payload.from) }
    else if (payload.to) param = { to: String(payload.to) }
    else param = {}

    let searchParam = new URLSearchParams(param).toString()

    if (searchParam) url = `${url}&${searchParam}`

    return baseAxios.get(url)
  },

  getSsccHistories(payload: {
    limit: number
    offset: number
    from?: number
    to?: number
    sscc: string
  }) {
    let url = `/warehouse/sscc/history?limit=${payload.limit}&offset=${payload.offset}&sscc=${payload.sscc}`
    let param
    if (payload.from && payload.to)
      param = { from: String(payload.from), to: String(payload.to) }
    else if (payload.from) param = { from: String(payload.from) }
    else if (payload.to) param = { to: String(payload.to) }
    else param = {}

    let searchParam = new URLSearchParams(param).toString()

    if (searchParam) url = `${url}&${searchParam}`
    return baseAxios.get(url)
  },

  getAttenduHistories(payload: {
    limit: number
    offset: number
    from?: number
    to?: number
    id: string
  }) {
    let url = `/warehouse/attendu/${payload.id}/history?limit=${payload.limit}&offset=${payload.offset}`
    let param
    if (payload.from && payload.to)
      param = { from: String(payload.from), to: String(payload.to) }
    else if (payload.from) param = { from: String(payload.from) }
    else if (payload.to) param = { to: String(payload.to) }
    else param = {}

    let searchParam = new URLSearchParams(param).toString()

    if (searchParam) url = `${url}&${searchParam}`
    return baseAxios.get(url)
  },

  async getUtilisateur(payload: {
    client_code_nom: string
    warehouse_code_nom: string
    company_code_nom: string
  }): Promise<
    [
      {
        id: string
        name: string
      }
    ]
  > {
    let url = `/warehouse/history/user?client_code_nom=${payload.client_code_nom}&warehouse_code_nom=${payload.warehouse_code_nom}&company_code_nom=${payload.company_code_nom}`
    const result = await baseAxios.get(url)

    return result.data.entry
  },
  async getGeneralHistories(payload: HistoryRequest): Promise<{
    entry?: (AttenduHistory | ReferenceForHistory | SsccHistory)[]
    messageError?: string
    metadata?: {
      count?: number
      limit?: number
      offset?: number
      total?: number
      count_history?: number
    }
  }> {
    const stringifiedPayload: Record<string, string> = {}

    Object.keys(payload).forEach((key) => {
      const value = payload[key]
      if (value !== undefined) {
        stringifiedPayload[key] = String(value)
      }
    })
    let searchParam = new URLSearchParams(stringifiedPayload).toString()
    let url = `/warehouse/history?${searchParam}`

    return (await baseAxios.get(url)).data
  },

  getCommandeHistories(payload: {
    id: string
    offset: number
    limit: number
    from?: number
    to?: number
  }) {
    let url = `/warehouse/commande/history/${payload.id}?offset=${payload.offset}&limit=${payload.limit}`

    let param
    if (payload.from && payload.to)
      param = { start_date: String(payload.from), end_date: String(payload.to) }
    else if (payload.from) param = { from: String(payload.from) }
    else if (payload.to) param = { to: String(payload.to) }
    else param = {}

    let searchParam = new URLSearchParams(param).toString()

    if (searchParam) url = `${url}&${searchParam}`
    return baseAxios.get(url)
  },
}

export default historyApi
